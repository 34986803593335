<template>
    <div>
        <div class="container-fluid">

        <!-- Page Heading -->
            <div class="d-sm-flex align-items-center justify-content-between mb-4" style="padding-top:20px">
                <h1 class="h3 mb-0 text-gray-800">All Users</h1>
                <router-link to="/panel/categories/add" class=" d-sm-inline-block btn btn-sm btn-primary shadow-sm"> Advanced Search</router-link>
            </div>
            <p class="mb-4"><a target="_blank" >Click here for support</a>.</p>
            <div class="search">
            <center>
                <form class="" @submit.prevent="search">
                    <div class="input-group">
                    <input type="text" class="form-control bg-light border-0 small" placeholder="Search by username or email " v-model="keyword" aria-label="Search" aria-describedby="basic-addon2">
                    <div class="input-group-append">
                        <button @click="search" class="btn btn-primary" type="button">
                        <i class="fas fa-search fa-sm"></i>
                        </button>
                    </div>
                    </div>
                </form>
                <div class="copyright-container" v-if="search_products.length">
                    <a class="copyright-container__link" href="#">
                    <p class="copyright-container__text">
                        Oops! You've reached the end of the line<br>
                        Didn't find what you're looking for?<br>
                        <a href="#">Suggest an upload</a>
                    </p>
                    <img class="copyright-container__icon">
                </a>
                </div>
            </center>
            </div>
            <div class="table-responsive">
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Profile</th>
                            <th scope="col">Username</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone</th>
                            <th scope="col">momo</th>
                            <th scope="col">Status</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody v-if="products.length">
                        <tr v-for="(product, index) in products" :key="product.id">
                            <th scope="row"><div class="centered">{{index + 1}}</div></th>
                            <td v-if="product.provider"><img :src="`${product.image}`" height="70px" width="70px" alt="" srcset=""></td>
                            <td v-else><img :src="`${apiDomain}/${product.image}`" height="70px" width="70px" alt="" srcset=""></td>
                            <td>{{product.username}}</td>
                            <td>{{product.email}}</td>
                            <td>{{product.phone}}</td>
                            <td>{{product.momo}}</td>
                            <td>{{product.status}}</td>
                            <td>
                                <div>
                                    <span><button type="button" @click="route('/panel/users/'+product.username+'/view')" class="btn btn-primary btn-circle btn-sm"><i class="fa fa-eye"></i></button> </span>
                                    <span></span>
                                </div>
                            </td>
                            
                        </tr>
                    </tbody>
                    
                </table>
            </div>

            
            <div v-if="!products.length">
                <div class="empty-products" style="text-align:center;padding: 70px 0 ">
                    <div class="empty-img">

                        <svg xmlns="http://www.w3.org/2000/svg" height="80px" version="1.1" viewBox="0 0 512.00002 512.00002" width="80px">
                        <g id="surface1">
                        <path d="M 512 88.277344 L 0 88.277344 L 0 512 L 512 512 Z M 317.792969 397.242188 L 194.207031 397.242188 L 194.207031 256 L 156.75 293.453125 L 194.207031 256 L 231.664062 293.453125 L 194.207031 256 L 194.207031 397.242188 L 317.792969 397.242188 L 317.792969 256 L 280.335938 293.453125 L 317.792969 256 L 355.25 293.453125 L 317.792969 256 Z M 317.792969 397.242188 " style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,85.490196%,66.666667%);fill-opacity:1;" />
                        <path d="M 256 0 L 52.964844 0 L 0 88.277344 L 256 88.277344 Z M 256 0 " style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,76.862745%,46.666667%);fill-opacity:1;" />
                        <path d="M 512 88.277344 L 459.035156 0 L 256 0 L 256 88.277344 Z M 512 88.277344 " style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,71.372549%,33.333333%);fill-opacity:1;" />
                        <path d="M 326.621094 141.242188 L 185.378906 141.242188 C 180.503906 141.242188 176.550781 137.289062 176.550781 132.414062 C 176.550781 127.539062 180.503906 123.585938 185.378906 123.585938 L 326.621094 123.585938 C 331.496094 123.585938 335.449219 127.539062 335.449219 132.414062 C 335.449219 137.289062 331.496094 141.242188 326.621094 141.242188 Z M 326.621094 141.242188 " style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,71.372549%,33.333333%);fill-opacity:1;" />
                        <path d="M 388.414062 406.070312 L 123.585938 406.070312 C 118.710938 406.070312 114.757812 402.117188 114.757812 397.242188 C 114.757812 392.367188 118.710938 388.414062 123.585938 388.414062 L 388.414062 388.414062 C 393.289062 388.414062 397.242188 392.367188 397.242188 397.242188 C 397.242188 402.117188 393.289062 406.070312 388.414062 406.070312 Z M 388.414062 406.070312 " style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,31.372549%,13.72549%);fill-opacity:1;" />
                        <path d="M 194.207031 406.070312 C 189.332031 406.070312 185.378906 402.117188 185.378906 397.242188 L 185.378906 256 C 185.378906 251.125 189.332031 247.171875 194.207031 247.171875 C 199.082031 247.171875 203.035156 251.125 203.035156 256 L 203.035156 397.242188 C 203.035156 402.117188 199.082031 406.070312 194.207031 406.070312 Z M 194.207031 406.070312 " style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,31.372549%,13.72549%);fill-opacity:1;" />
                        <path d="M 231.664062 302.28125 C 229.320312 302.28125 227.078125 301.351562 225.421875 299.695312 L 194.207031 268.480469 L 162.992188 299.695312 C 159.605469 303.203125 154.015625 303.300781 150.511719 299.914062 C 147.003906 296.527344 146.90625 290.9375 150.292969 287.429688 C 150.363281 287.355469 150.4375 287.285156 150.511719 287.214844 L 187.964844 249.757812 C 191.414062 246.3125 197 246.3125 200.449219 249.757812 L 237.902344 287.214844 C 241.351562 290.660156 241.347656 296.25 237.902344 299.699219 C 236.246094 301.351562 234.003906 302.28125 231.664062 302.28125 Z M 231.664062 302.28125 " style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,31.372549%,13.72549%);fill-opacity:1;" />
                        <path d="M 317.792969 406.070312 C 312.917969 406.070312 308.964844 402.117188 308.964844 397.242188 L 308.964844 256 C 308.964844 251.125 312.917969 247.171875 317.792969 247.171875 C 322.667969 247.171875 326.621094 251.125 326.621094 256 L 326.621094 397.242188 C 326.621094 402.117188 322.667969 406.070312 317.792969 406.070312 Z M 317.792969 406.070312 " style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,31.372549%,13.72549%);fill-opacity:1;" />
                        <path d="M 355.25 302.28125 C 352.90625 302.28125 350.664062 301.351562 349.007812 299.695312 L 317.792969 268.480469 L 286.578125 299.695312 C 283.070312 303.082031 277.484375 302.988281 274.097656 299.480469 C 270.792969 296.058594 270.792969 290.636719 274.097656 287.214844 L 311.550781 249.757812 C 315 246.3125 320.585938 246.3125 324.035156 249.757812 L 361.488281 287.214844 C 364.9375 290.664062 364.933594 296.253906 361.488281 299.699219 C 359.832031 301.351562 357.589844 302.28125 355.25 302.28125 Z M 355.25 302.28125 " style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,31.372549%,13.72549%);fill-opacity:1;" />
                        </g>
                        </svg>
                    </div>
                    <p style="color:grey;font-size:14px; padding-top:10px">
                        No Creatives found
                    </p>
                </div>
            </div>
            
        </div>
        <base-pagination :pagination="pagination" @fetchNext="fetchNext"/>
        <base-delete-modal @deleteItem="deleteProduct" :name="bin.name"/>
    </div>
</template>

<script>
    import Crud from '../../services/main/CRUD.js'
    import BaseDeleteModal from '../../components/BaseDeleteModal.vue'
    import PaginationMixin from '../../mixins/paginationMixin.js'
    import BasePagination from '../../components/BasePagination.vue'
    import {axios} from '../../services/axios.js'
    export default {
        data() {
            return {
                keyword: "",
                pagination: {},
                products: [],
                search_products: [],
                //change_status_loader: false,
                empty: false,
                public_path: `${process.env.VUE_APP_API_DOMAIN}/files/products/`,
                bin: {
                    id: 0,
                    name: "",
                    index: null,

                },
                apiDomain: process.env.VUE_APP_API_DOMAIN
            }
        },
        mixins: [PaginationMixin],
        components: {
            BaseDeleteModal, BasePagination
        },
        methods: {
            moveToBin(id,name, index) {
                this.bin = {
                    id, name, index
                }
            },
            async fetchNext(url) {
                this.$store.dispatch('setLoading',true)
                try {
                    var obj_data = await axios.get(url)
                    var photos = obj_data.data
                    const { current_page,last_page, first_page_url, prev_page_url, next_page_url, path} = photos
                    var meta = { last_page, current_page, path}
                    var links = {next_page_url, prev_page_url}
                    this.pagination = this.makePagination(meta, links)
                    this.products = photos.data
                } catch (error) {
                    console.log(error)
                }
                this.$store.dispatch('setLoading',false)

            },

            async search() {
                if(this.keyword) {
                    this.$store.dispatch('setLoading',true)
                    try {
                        var products = await Crud.index('admin/user-search/'+ this.keyword)
                        const { current_page,last_page, first_page_url, prev_page_url, next_page_url, path} = products
                        var meta = { last_page, current_page, path}
                        var links = {next_page_url, prev_page_url}
                        this.pagination = this.makePagination(meta, links)
                        this.products = products.data
                    
                    
                   
                    
                
                        this.$store.dispatch('setLoading',false)
                        if(!this.products.length) {
                            this.empty = true
                        }
                    } catch (e) {
                        this.$store.dispatch('setLoading',false)

                    }
                } else {
                    alert("keyword field is empty")
                }
                
            },

            changeStatus(id, name, type) {
                this.setProductStatusLoader(id, true)
                Crud.index(`admin/photos/change-status/${id}/${type}`)
                .then((res) => {
                    console.log(res)
                    
                    this.$store.dispatch('success',`${name} status set to ${type}`)
                    this.products = this.products.filter((item) => {
                        if(item.id == id) {
                            item.status = type
                        }
                        return item
                    })
                    this.setProductStatusLoader(id, false)
                })
                .catch((err) => {
                    console.log(err)
                    this.setProductStatusLoader(id, false)
                })
              


            },

            setProductStatusLoader(id, state) {
                this.products = this.products.filter((item) => {
                    if(item.id == id) {
                        item.loading = state
                    }
                    return item
                })
                //console.log(product)
            },

            async getProducts() {
                this.$store.dispatch('setLoading',true)
                try {
                    var products = await Crud.index('admin/users')
                    const { current_page,last_page, first_page_url, prev_page_url, next_page_url, path} = products
                    var meta = { last_page, current_page, path}
                    var links = {next_page_url, prev_page_url}
                    this.pagination = this.makePagination(meta, links)
                    this.products = products.data
                    
                    
                   
                    
                
                    this.$store.dispatch('setLoading',false)
                    if(!this.products.length) {
                        this.empty = true
                    }
                } catch (e) {
                    this.$store.dispatch('setLoading',false)

                }
            },
            deleteProduct() {
                this.$store.dispatch('setLoading',true)
                Crud.delete('admin/photos',this.bin.id)
                .then((res) => {
                    console.log(res)
                    this.$store.dispatch('setLoading',false)
                    this.$store.dispatch('success',`${this.bin.name} deleted`)
                    this.products = this.products.filter(item => item.id != this.bin.id)
                    //this.$router.go(-1)
                })
                .catch((err) => {
                    console.log(err)
                    this.$store.dispatch('setLoading',false)
                })

            },
            route(path) {
                this.$router.push(path)
            },
            loader() {
                this.$store.dispatch('setLoading',true)
            }
        },
        created() {
           
            this.getProducts()
            $('.carousel').carousel({
                interval: 2000
            })
        }
    }
</script>

<style lang="scss" scoped>
.search {
    padding-bottom:30px;
}
.product {
    .card {
        background-color: #ffffff;
        border-radius: 20px
    }

    p {
        color: #b7b1b1;
        font-weight: 500
    }

    .discBtn {
        background-color: #21afa7;
        border-radius: 8px;
        font-size: 13px;
        font-weight: 500
    }

    .fa-heart {
        color: #f05c5d
    }

    .fa-balance-scale {
        color: #968888
    }

    .fa-arrows {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg)
    }

    .buy {
        border-radius: 20px;
        background-color: #e8f6f6;
        color: #2bc4ba
    }

    .cart {
        background-color: #2bc4ba;
        border-radius: 30px;
        position: relative;
        right: 35px
    }

    .btn:focus {
        outline: none;
        box-shadow: none
    }

    *:focus {
        outline: none
    }
}
    .carousel-control-next,
    .carousel-control-prev {
        filter: invert(100%);
    }
   
    td, th  {
        text-align:center;
    }
</style>